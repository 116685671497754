<template>
    <div>
        <slot name="button">
            <v-btn :style="theme" prepend="mdi-auto-fix" @click="toggleDialog()"><v-icon left>
                    mdi-auto-fix
                </v-icon>Generate Using AI</v-btn>
        </slot>
        <app-dialog title="Generate using AI" :show="showDialog" @close="toggleDialog()">
            <alert-message v-if="msg" :error="msg"></alert-message>
            <v-text-field v-model="localPrompt" outlined></v-text-field>
            <v-textarea v-model="generatedText" outlined></v-textarea>
            <div class="text-right">
                <v-btn outlined :disabled="!localPrompt" @click="generateText()" :loading="generatingText"><v-icon left>
                        mdi-auto-fix
                    </v-icon>Generate</v-btn><br>
                <v-btn :style="theme" @click="selectText()" block class="mt-2">Select</v-btn>
            </div>
        </app-dialog>
    </div>
</template>

<script>
import appConstants from '../utils/appConstants';
import AlertMessage from './AlertMessage.vue';
import AppDialog from './AppDialog.vue'
export default {
    components: { AppDialog, AlertMessage },
    props: {
        prompt: {
            type: String,
            default: ""
        },
    },
    data() {
        return {
            showDialog: false,
            localPrompt: this.prompt,
            generatedText: '',
            generatingText: false,
            editPrompt: false
        }
    },
    watch: {
        prompt(newValue, oldValue) {
            this.localPrompt = newValue
        }
    },
    methods: {
        toggleDialog() {
            this.showDialog = !this.showDialog
        },
        async generateText() {
            try {
                this.msg = ''
                this.generatingText = true
                let response = await this.postItem(`${appConstants.AI_API}/text`, { prompt: this.localPrompt })
                if (response.result)
                    this.generatedText = response?.result
            } catch (error) {
                this.handleError(error)
            } finally {
                this.generatingText = false
            }
        },
        async selectText() {
            this.$emit('textGenerated', this.generatedText)
            this.toggleDialog()
        }
    },
}
</script>

<style lang="scss" scoped></style>